import React, { useState } from "react"
import loadable from "@loadable/component"
import "../../css/typography-overpass.css"
const ChatComponent = loadable(() => import("../../components/Chat Iframe"))

function Chat() {
  const [hideSendBox, setHideSendBox] = useState(false)
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="regioit"
      token="eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzUxNTk1NDkuMjYwOTcyLCJleHAiOjIzMDU4Nzk1NDkuMjYwOTcyLCJib3RJZCI6ImRkeTE1MnE4bDM4bjJ0emEiLCJpc3MiOiJjb252YWlzZSIsImF1ZCI6ImNvbnZhaXNlLWNvdXJpZXIifQ.Hsz8pIYVWOoK-mI8cu1FpaZVYJeOwzbd9PH17OtBCtTvwJx3rhE3_5psO048-XZh1HIeAS5loHVPzLC_wxgM2llxRlM6t7QYbDjKzd6BmMoMgpiJ_vkVaSl_9x2uywlUbv9uBuC61hIqwQE-88qAAzAN6I6hDcd3M2sjtlCqEO7X3fklI7Nt3VWz1pOWEKY0yB-oabLg1pdGISGki9t8R91q4ZID5DvN5FspOEzFKiusq6i9cF3ooqn7FmMBcmxCrgGvsrk2LTaKYMsXLsD9wP_eg7_HPZe0wICTDB_oqYH5D1ekA0_w7WRswAqA95V_xPK5dwdTYgPeNSDvs9jJlDAB9YNKG4tAtBKRilYRpQGk6tYvfkDXXPjH-c2SNkO3puRm3Pd3Q1frY8P-lq2QraCi2vL8pt_wdrX-d7lKQk6beiuQlsKIvjd5IKlqJaUiZPkcnVM6jf__Je41DypqP7j0JYJsCpPFMl-4qA6QhGRzIinoQ-H1UpnprqhUHm0iO-bz-4szvFLnRJfOvxJFbcNg9ShqbL5l2wEPrh_ziQ_-5weT4L3-U-56PPKQdxlwSzLIMwv2SWpje-iEX965jgpoJoplJGD8N7rKc2HGdQ9v3NcWnjJbBp-vBKRpQY27fACrUc0I92xKQzgu8u4c4n2y7SQ7CaZLYbDXK6XfDjg"
      botId="ddy152q8l38n2tza"
      domain="https://courier.convaise.com/frontend/v3/directline"
      webSocket={true}
      hasGDPR={false}
      onHideSendBox={value => setHideSendBox(value)}
      autoFocusVersion="v1"
      // gdprButtonText="Zustimmen"
      // gdprText={
      //   <>
      //     Die{" "}
      //     <a
      //       href="https://www.gegenbauer.de/datenschutzerklaerung/"
      //       target="_blank"
      //     >
      //       Datenschutzerklärung
      //     </a>{" "}
      //     habe ich zur Kenntnis genommen und ich erkläre mich damit
      //     einverstanden, dass meine Daten zum Zwecke der Bearbeitung meiner
      //     Anfrage verarbeitet werden. Ihre Einwilligung können Sie jederzeit
      //     widerrufen. Durch den Widerruf der Einwilligung wird die
      //     Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
      //     erfolgten Verarbeitung nicht berührt.
      //   </>
      // }
      styleOptions={{
        primaryFont: "Overpass,sans-serif,-apple-system,BlinkMacSystemFont",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#CD071A",
        botAvatarBackgroundColor: "#fff",
        hideSendBox,
        transcriptVisualKeyboardIndicatorWidth: 1,
        transcriptActivityVisualKeyboardIndicatorWidth: 1,
        // hideSendBox: true,
        // primaryFont:
        //   'muli,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      // styleCustomizations={
      //   {
      // "--button-blue": "#E30014",
      // "--button-blue-light": "#C30C0E",
      // "--button-orange": "#2d3234",
      // "--button-orange-light": "#000",
      // "--button-orange-text": "#fff",
      // "--button-red": "#E30014",
      // "--button-red-light": "#C30C0E",
      // "--button-green": "rgba(40, 167, 69, 1)",
      // "--button-green-light": "rgba(40, 167, 69, 0.8)",
      // "--font":
      //   'Overpass,sans-serif,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      // "--button-font-size": "16px",
      // "--button-font-weight": "400",
      // "--button-border-radius": "0",
      // "--button-box-shadow": "rgb(0 0 0 / 25%) 0px 0px 20px 0px",
      // "--button-padding": "8px 24px !important",
      // "--button-height": "42px",
      // "--card-border-radius": "5px",
      // "--font-size-normal": "16px",
      // "--line-height-normal": "26px",
      // "--font-size-large": "36px",
      //   }
      // }
      overrideLocalizedStrings={{
        TEXT_INPUT_PLACEHOLDER: "Ihre Frage...",
      }}
      styleCustomizations={{
        "--button-blue": "#134d88",
        "--button-blue-light": "#5a82ac",
        // "--button-red": "rgb(120, 125, 141)",
        // "--button-red-light": "rgba(120, 125, 141, 0.8)",
      }}
      // css="https://static.convaise.com/webchat/v4/convaise-assistant-min_v4-1.css"
    />
  )
}

export default Chat
